<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton
        variant="outline" 
        type="submit" 
        color="primary" 
        class='list-new-btn'
        @click="$router.push({path: '/manager/customers/create'})"
        >
        <CIcon name="cil-check-circle" />New customer
      </CButton> 

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Customers</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              sorter
              striped
              table-filter
              :items="items"
              :fields="fields"
              :items-per-page="perPage"
              :pagination="$options.paginationProps"
              index-column
            >
              <template #location="{item, index}">
                <td class="py-2">
                  <p v-if="item.location">{{ item.location.name }}</p>
                </td>
              </template>
              
              <template #actions="{item, index}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="detailClicked(item, index)"
                  >
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import CustomerAPI from '/app/src/api/customer.js'

export default {
  name: 'Customers',
  data: () => {
    return {
      items: [],
      fields: [
        { key: 'name' },
        { key: 'city' },
        { key: 'telephone' },
        { key: 'mail' },
        { key: 'location_name' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function() {
    this.getCustomers();
  },
  methods: {
    getCustomers: function() {
      this.$store.dispatch('loading')
      let locationAPI = new CustomerAPI()
      let self = this
      locationAPI.all()
      .then((customers) => {
        console.log(customers)
        this.$store.dispatch('stopLoading')
        for(let i = 0; i < customers.length; i++) {
          customers[i].location_name = customers[i].location.name
        }
        self.items = customers
        
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
      })
    },
    detailClicked (item, index) {
      const itemLink = `/manager/customers/${item.id.toString()}`
      this.$router.push({path: itemLink})
    }
  }
}
</script>
